<!--
 * @Author: Jason
 * @Description: 家庭成员的信息
-->
<template>
    <div>
        <el-form ref="userForm" v-loading="loading" :model="userForm" label-width="120px" class="demo-ruleForm">
            <!-- 配偶模块  -->
            <div v-if="marriedStatus==='1'||marriedStatus==='4'" class="card-container">
                <div class="card-heard">主申请人配偶</div>
                <div v-for="(item,idx) in userForm.subsetList" :key="item.id">
                    <el-row v-if="item.relation==='3'" class="block-top">
                        <div class="one-input">
                            <el-form-item label="将来是否随行:" :prop="'subsetList.'+idx+'.follow'" :rules="[{required: true,message: '请选择将来是否随行',trigger: 'change'}]">
                                <el-radio-group v-model="item.follow">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="2">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <el-col :span="12">
                            <!-- 左边输入框的内容 -->
                            <!-- 姓名 -->
                            <div class="one-input">
                                <el-form-item label="姓名:" :prop="'subsetList.'+idx+'.subsetName'" :rules="[{required: true,message: '请输入姓名',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.subsetName" class="full-width" placeholder="请输入" maxlength="20" clearable @blur="usernameBlur(item)" />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="姓名拼音:" prop="pinyin">
                                    <el-input v-model="item.pinyin" class="full-width" placeholder="请输入" maxlength="50" clearable @input="pinyinInput(item)" />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="曾用名:" prop="usedName">
                                    <el-input v-model.trim="item.usedName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                </el-form-item>
                            </div>

                            <div class="one-input">
                                <el-form-item label="出生日期:" :prop="'subsetList.'+idx+'.birthday'" :rules="[{required: true,message: '请选择出生日期',trigger: 'change'}]">
                                    <el-date-picker v-model="item.birthday" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM-dd" class="full-width" type="date" placeholder="选择出生日期" />
                                </el-form-item>
                            </div>

                            <div class="one-input">
                                <el-form-item label="出生城市:" :prop="'subsetList.'+idx+'.birthPlaceAboard'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.birthPlaceAboard" style="margin-top: 9px;" @change="changeBirthPalce($event,item)">
                                            <el-radio :label="0">国内</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="item.birthPlaceAboard===0" :key="3" class="inline-item" label="" :prop="'subsetList.'+idx+'.birthPlace.area'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]" style="margin-bottom: 0px">
                                            <CitySelect v-model="item.birthPlace.area" class="city-inline" :placeholder="holderText" />
                                        </el-form-item>
                                        <el-form-item v-if="item.birthPlaceAboard===1" :key="4" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.birthPlace.foreign'" :rules="[{required: true,message: '请输入出生城市',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.birthPlace.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="目前居住国家:" :prop="'subsetList.'+idx+'.liveCountry'" :rules="[{required: true,message: '请选择目前居住国家',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.liveCountry" style="margin-top: 9px;" @change="changeLiveCountry($event,item)">
                                            <el-radio label="中国">中国</el-radio>
                                            <el-radio label="国外">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="item.liveCountry==='国外'" label="" class="city-inline-add" :prop="'subsetList.'+idx+'.liveCountryTxt'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.liveCountryTxt" placeholder="请填写国家名称" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>

                            <!-- 职业 -->
                            <div class="one-input">
                                <el-tooltip content="如无业，请选择其他并填写【无】" placement="top">
                                    <img class="sigh-img" style="left: 33px;top:7px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="职业:" :prop="'subsetList.'+idx+'.occupation'" :rules="[{required: true,message: '请选择职业',trigger: 'change'}]">
                                    <el-select v-model="item.occupation" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable @change="item.occupationName=''">
                                        <el-option v-for="occupitem in workOption" :key="occupitem.id" :label="occupitem" :value="occupitem" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="item.occupation==='在职'" class="one-input">
                                <el-form-item label="" :prop="'subsetList.'+idx+'.occupationName'" :rules="[{required: true,message: '请输入职位名称',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.occupationName" class="input-width" placeholder="请输入职位名称" maxlength="20" clearable />
                                </el-form-item>
                            </div>

                        </el-col>
                        <el-col :span="12">
                            <!-- 右边输入框的内容 -->
                            <!-- 通行证 -->
                            <div class="one-input doubles">
                                <el-form-item label="港澳通行证号码：" label-width="140px" :prop="'subsetList.'+idx+'.passNumber'">
                                    <div style="display:flex;">
                                        <div class="input-width150">
                                            <el-input v-model.trim="item.passNumber" onkeyup="this.value=this.value.replace(/[, ]/g,'')" :disabled="disabled" clearable maxlength="20" placeholder="外国籍请填写护照号码" />
                                        </div>
                                        <el-checkbox v-model="checked" style="position:relative;top:-2px;left:12px;">暂未办理</el-checkbox>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input doubles">
                                <el-form-item label="中国身份证号码：" label-width="140px" :prop="'subsetList.'+idx+'.identityCard'">
                                    <div class="input-width">
                                        <el-input v-model.trim="item.identityCard" clearable maxlength="18" placeholder="请输入" />
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="香港身份证号码:" prop="hkIdentityCard">
                                    <div class="input-width">
                                        <el-input v-model.trim="item.hkIdentityCard" placeholder="若有请填写" maxlength="8" clearable onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" />
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <!-- <el-form-item label="国籍：" style="position: relative;" :prop="'subsetList.'+idx+'.nationalityType'" :rules="[{required: true, validator:validNationalityType,trigger: 'change'}]">
                                    <el-radio-group v-model="item.nationalityType">
                                        <el-radio :label="1">中国</el-radio>
                                        <el-radio :label="2">国外</el-radio>
                                    </el-radio-group>
                                    <el-form-item v-if="item.nationalityType == 2" :prop="'subsetList.'+idx+'.nationality'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="position: absolute; top:0;right: -192px;margin:0">
                                        <el-input v-model.trim="item.nationality" style="width:200px;" clearable maxlength="20" placeholder="请填写国家名称" />
                                    </el-form-item>
                                </el-form-item> -->
                                <el-form-item label="国籍：" label-width="140px" :prop="'subsetList.'+idx+'.nationality'" :rules="[{required: true, message: '请输入国籍',trigger: 'change'}]">
                                    <el-select v-model="item.nationality" clearable filterable remote reserve-keyword placeholder="请输入" :remote-method="queryCountryAsync" :loading="opctionLoading">
                                        <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-tooltip placement="top">
                                    <div slot="content">仅有毕业证无效<br />必须有学位证</div>
                                    <img class="sigh-img" style="left: 21px;bottom: 32px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="学位证:" :prop="'subsetList.'+idx+'.hasDegree'" :rules="[{required: true,message: '请选择学位证',trigger: 'change'}]">
                                    <el-select v-model="item.hasDegree" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable>
                                        <el-option v-for="hasitem in degreeList" :key="hasitem.id" :label="hasitem.name" :value="hasitem.idx" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="目前居住地址:" :prop="'subsetList.'+idx+'.nows'" :rules="[{required: true,message: '请选择目前居住地址',trigger: 'change'}]">
                                    <el-radio-group v-model="item.nows" @change="changeNows($event,item)">
                                        <el-radio :label="1">与主申请人相同</el-radio>
                                        <el-radio :label="0">与主申请人不相同</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <!-- 目前居住地址与主申请人不同 -->
                            <div class="one-input">
                                <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.addressAboard'" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.addressAboard" style="margin-top: 9px;" @change="changeAddress($event,item)">
                                            <el-radio :label="0">国内</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <!-- 国内不同住址 -->
                                        <el-form-item v-if="item.addressAboard===0" class="inline-item" label="" :prop="'subsetList.'+idx+'.address.area'" :rules="[{required: true,message: '请选择地址',trigger: 'change'}]" style="margin-bottom: 0px">
                                            <CitySelect v-model="item.address.area" class="city-inline" :placeholder="holderText" />
                                        </el-form-item>
                                        <!-- 国外不同住址 -->
                                        <el-form-item v-if="item.addressAboard===1" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.address.foreign'" :rules="[{required: true,message: '请输入地址',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.address.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                            <!-- 详细地址 -->
                            <div class="one-input">
                                <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.address.details'" :rules="[{required: true,message: '请输入详细地址',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.address.details" type="textarea" class="detailed-address" maxlength="100" :rows="2" placeholder="请输入详细地址" clearable />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 子女模块 -->
            <div v-if="marriedStatus!='2'" class="card-container">
                <div class="card-heard">子女</div>
                <div v-for="(item,idx) in userForm.subsetList" :key="item.id">
                    <div v-if="item.relation==='4'" class="block-box sis-box">
                        <div class="block-heard">
                            <p />
                            <div class="del-cur" @click="removeMember(item,idx)">删除</div>
                        </div>
                        <el-row class="block-top">
                            <div class="one-input">
                                <el-tooltip placement="top">
                                    <div slot="content">1、18周岁以上子女，不能随行<br /> 2、如对子女无抚养权，不能随行</div>
                                    <img class="sigh-img" style="left: 30px;bottom: 34px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="将来是否随行:" label-width="160px" :prop="'subsetList.'+idx+'.follow'" :rules="[{required: true,message: '请选择将来是否随行',trigger: 'change'}]">
                                    <el-radio-group v-model="item.follow">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <el-col :span="12">
                                <!-- 左边输入框的内容 -->
                                <!-- 姓名 -->
                                <div class="one-input">
                                    <el-form-item label="姓名:" :prop="'subsetList.'+idx+'.subsetName'" :rules="[{required: true,message: '请输入姓名',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.subsetName" class="full-width" placeholder="请输入" maxlength="20" clearable  @blur="usernameBlur(item)"/>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                <el-form-item label="姓名拼音:" prop="pinyin">
                                    <el-input v-model="item.pinyin" class="full-width" placeholder="请输入" maxlength="50" clearable @input="pinyinInput(item)"/>
                                </el-form-item>
                            </div>
                                <div class="one-input">
                                    <el-form-item label="曾用名:" prop="usedName">
                                        <el-input v-model.trim="item.usedName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="性别:" :prop="'subsetList.'+idx+'.sex'" :rules="[{required: true,message: '请选择性别',trigger: 'change'}]">
                                        <el-select v-model="item.sex" class="full-width" placeholder="请选择" clearable>
                                            <el-option label="男" :value="1" />
                                            <el-option label="女" :value="2" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="出生日期:" :prop="'subsetList.'+idx+'.birthday'" :rules="[{required: true,message: '请选择出生日期',trigger: 'change'}]">
                                        <el-date-picker v-model="item.birthday" :picker-options="pickerBeginDateBefore" class="full-width" value-format="yyyy-MM-dd" type="date" placeholder="选择出生日期" />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="出生城市:" :prop="'subsetList.'+idx+'.birthPlaceAboard'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.birthPlaceAboard" style="margin-top: 9px;" @change="changeBirthPalce($event,item)">
                                                <el-radio :label="0">国内</el-radio>
                                                <el-radio :label="1">国外</el-radio>
                                            </el-radio-group>
                                            <el-form-item v-if="item.birthPlaceAboard===0" :key="5" class="inline-item" label="" :prop="'subsetList.'+idx+'.birthPlace.area'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                                <CitySelect v-model="item.birthPlace.area" class="city-inline" :placeholder="holderText" />
                                            </el-form-item>
                                            <el-form-item v-if="item.birthPlaceAboard===1" :key="6" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.birthPlace.foreign'" :rules="[{required: true,message: '请输入出生城市',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.birthPlace.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <!-- 右边输入框的内容 -->
                                <div class="one-input">
                                    <el-tooltip placement="top">
                                        <div slot="content">1、如已上学，请填写【学生】<br /> 2、 如未上学，请填写【无】</div>
                                        <img class="sigh-img" style="left: 33px;bottom: 32px;" src="@/assets/img/hover.png" alt="">
                                    </el-tooltip>
                                    <el-form-item label="职业:" :prop="'subsetList.'+idx+'.occupation'" :rules="[{required: true,message: '请选择职业',trigger: 'change'}]">
                                        <el-select v-model="item.occupation" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable @change="item.occupationName=''">
                                            <el-option v-for="puitem in studentsList" :key="puitem.id" :label="puitem" :value="puitem" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div v-if="item.occupation==='在职'" class="one-input">
                                    <el-form-item label="" :prop="'subsetList.'+idx+'.occupationName'" :rules="[{required: true,message: '请输入职位名称',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.occupationName" class="input-width" placeholder="请输入职位名称" maxlength="20" clearable />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="香港身份证号码:" prop="hkIdentityCard">
                                        <div class="input-width">
                                            <el-input v-model.trim="item.hkIdentityCard" placeholder="若有请填写" maxlength="8" clearable onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" />
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <!-- <el-form-item label="国籍：" style="position: relative;" :prop="'subsetList.'+idx+'.nationalityType'" :rules="[{required: true, validator:validNationalityType,trigger: 'change'}]">
                                        <el-radio-group v-model="item.nationalityType">
                                            <el-radio :label="1">中国</el-radio>
                                            <el-radio :label="2">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="item.nationalityType == 2" :prop="'subsetList.'+idx+'.nationality'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="position: absolute; top:0;right: -192px;margin:0">
                                            <el-input v-model.trim="item.nationality" style="width:200px;" clearable maxlength="20" placeholder="请填写国家名称" />
                                        </el-form-item>
                                    </el-form-item> -->
                                    <el-form-item label="国籍：" label-width="140px" :prop="'subsetList.'+idx+'.nationality'" :rules="[{required: true, message: '请输入国籍',trigger: 'change'}]">
                                        <el-select v-model="item.nationality" clearable filterable remote reserve-keyword placeholder="请输入" :remote-method="queryCountryAsync" :loading="opctionLoading">
                                            <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="目前居住国家:" :prop="'subsetList.'+idx+'.liveCountry'" :rules="[{required: true,message: '请选择目前居住国家',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.liveCountry" style="margin-top: 9px;" @change="changeLiveCountry($event,item)">
                                                <el-radio label="中国">中国</el-radio>
                                                <el-radio label="国外">国外</el-radio>
                                            </el-radio-group>
                                            <el-form-item v-if="item.liveCountry==='国外'" label="" class="city-inline-add" :prop="'subsetList.'+idx+'.liveCountryTxt'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.liveCountryTxt" placeholder="请填写国家名称" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="目前居住地址:" :prop="'subsetList.'+idx+'.nows'" :rules="[{required: true,message: '请选择目前居住地址',trigger: 'change'}]">
                                        <el-radio-group v-model="item.nows" @change="changeNows($event,item)">
                                            <el-radio :label="1">与主申请人相同</el-radio>
                                            <el-radio :label="0">与主申请人不相同</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <!-- 目前居住地址与主申请人不同 -->
                                <div class="one-input">
                                    <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.addressAboard'" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.addressAboard" style="margin-top: 9px;" @change="changeAddress($event,item)">
                                                <el-radio :label="0">国内</el-radio>
                                                <el-radio :label="1">国外</el-radio>
                                            </el-radio-group>
                                            <!-- 国内不同住址 -->
                                            <el-form-item v-if="item.addressAboard===0" class="inline-item" label="" :prop="'subsetList.'+idx+'.address.area'" :rules="[{required: true,message: '请选择地址',trigger: 'change'}]" style="margin-bottom: 0px">
                                                <CitySelect v-model="item.address.area" class="city-inline" :placeholder="holderText" />
                                            </el-form-item>
                                            <!-- 国外不同住址 -->
                                            <el-form-item v-if="item.addressAboard===1" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.address.foreign'" :rules="[{required: true,message: '请输入地址',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.address.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                                <!-- 详细地址 -->
                                <div class="one-input">
                                    <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.address.details'" :rules="[{required: true,message: '请输入详细地址',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.address.details" type="textarea" class="detailed-address" :rows="2" placeholder="请输入详细地址" clearable />
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="add-people" @click="addMember('son')">+新增成员</div>
            </div>
            <!-- 保存按钮 -->
            <div class="operations-group">
                <el-button class="person-btn" @click="saveForm('last')">上一步</el-button>
                <el-button v-if="(user.baseSchedule - 1) < 2" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
                <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
            </div>
        </el-form>
    </div>
</template>

<script lang="javascript">
import CitySelect from "@/components/CitySelect/CitySelect";
import { saveSubset, querySearchCountry,getPingYin } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "Parent",
    components: {
        CitySelect,
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            checked: false,
            opctionLoading: false,
            countryOption: [], // 国籍列表
            userForm: {
                option: "",
                subsetList: [
                    {
                        id: 0, //当前人物id
                        orderId: 0, //订单id
                        subsetName: "", //成员名称
                        pinyin: "", // 姓名拼音
                        usedName: "", //曾用名
                        sex: 1, //性别:0为未知,1为男,2为女
                        relation: "1", //关系:1：主申父亲,2：主申母亲,3:配偶 ,4：子女,5：兄弟姐妹
                        hasDegree: "", //是否有学位 0-无学位 1-学士 2-硕士 3-博士
                        nows: 0, //现居住地址 0-不一样 1-一样
                        occupation: "", //职业
                        hkIdentityCard: "", //香港身份证
                        birthday: "", //生日
                        follow: 0, //是否随同 0：待定 1：跟随 2：不跟随
                        addressAboard: 0, // 现居住地址 0-国内 1-国外
                        liveCountry: "", // 现定居国家 传字符串 目前居住国家 - 国外
                        liveCountryTxt: "", // 为国外时 需要传的参数 最后要删掉
                        occupationName: "", //在职的职业字段
                        address: {
                            //目前居住地址
                            area: [], //中国的话选城市
                            foreign: "", //外国的话输入城市
                            details: "", //目前居住地址与主申请人不同的详细地址
                            country: "", //目前居住国家国外的话 输入框国外的国家
                        },
                        birthPlace: {
                            //出生地址
                            area: [],
                            foreign: "",
                        },
                        birthPlaceAboard: 1, //出生城市 0-国内 1-国外
                        deceased: 0, //是否已故 0-否 1-是
                        nationalityType: 3, //1-国内 2-国外 3-搜索进来
                        nationality: "" //	国外国籍信息
                    },
                ],
            },
            degreeList: [
                { name: "无学位", idx: 0 },
                { name: "无学位（专科）", idx: 6 },
                { name: "无学位（本科）", idx: 5 },
                { name: "无学位（硕士）", idx: 4 },
                { name: "学士学位", idx: 1 },
                { name: "硕士学位", idx: 2 },
                { name: "博士学位", idx: 3 },
            ],
            studentsList: ["在职", "未上学", "学生", "无业"],
            maritalList: ["已婚", "离婚", "丧偶"],
            sisterlList: ["未婚", "已婚", "离婚", "丧偶"],
            sisWorkList: [
                "企业管理者（高级管理者）",
                "企业管理者（包括初、中级层管理者）",
                "自主创业（有实际运营的公司）",
                "普通职员（办公室、写字楼）",
                "专业人员（医生/教师/律师/记者/文体）",
                "个体经营者&承包商",
                "政府机关干部&公务人员",
                "在校学生",
                "家庭主妇",
                "退休人员",
                "应届毕业生",
                "其他",
            ],
            workOption: ["在职", "退休", "无"],
            holderText: "请选择城市",
            marriedStatus: "1", // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
            haveSpouse: false, //是否有配偶
            haveParent: false, //是否有父母
            loading: false,
            isSave: false, //防抖loading
            isNext: false,
            infomation: {}, //主申请人信息
            disabled: false, //配偶禁止输入港澳通行证
        };
    },
    computed: {
        ...mapState(["user"])
    },
    watch: {
        checked(val) {
            this.userForm.subsetList[0].isHandled = !val ? 0 : 1;
            this.disabled = val ? true : false;
        },
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.loading = true;
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.code === 200) {
                    this.userForm.subsetList = res.data.subsetList;
                    this.marriedStatus = res.data.information.married;
                    this.infomation = res.data.information;
                    res.data.subsetList.forEach((ele) => {
                        // 如果有配偶 不添加
                        if (ele.relation === "3") {
                            this.haveSpouse = true;
                            this.checked =
                                Number(this.userForm.subsetList[0].isHandled) ==
                                1
                                    ? true
                                    : false;
                            this.disabled =
                                Number(this.userForm.subsetList[0].isHandled) ==
                                1
                                    ? true
                                    : false;
                        }
                        // 性别 int类型 0=>''
                        if (ele.sex === 0) {
                            ele.sex = "";
                        }

                        // 如果不是中国就要 新参数占位
                        if (ele.liveCountry === "中国") {
                            this.$set(ele, "liveCountryTxt", "");
                        } else {
                            this.$set(ele, "liveCountryTxt", ele.liveCountry);
                            ele.liveCountry = "国外";
                        }
                        if(ele.subset_name_pinyin) {
                            this.$set(ele, 'pinyin', ele.subset_name_pinyin.family_name + ' ' + ele.subset_name_pinyin.given_name);
                        } else {
                            this.$set(ele, 'pinyin', "");
                        } 
                    });

                    // 申请人婚姻状况是 已婚和分居情况下 新增一个配偶模块
                    if (!this.haveSpouse) {
                        if (
                            this.marriedStatus === "1" ||
                            this.marriedStatus === "4"
                        ) {
                            this.userForm.subsetList.push({
                                orderId: this.$route.params.id,
                                relation: "3",
                                subsetName: "",
                                usedName: "",
                                pinyin: "",
                                birthday: "",
                                birthPlaceAboard: 0,
                                birthPlace: {
                                    area: [],
                                    foreign: "",
                                },
                                occupation: "",
                                occupationName: "",
                                liveCountry: "",
                                liveCountryTxt: "",
                                hkIdentityCard: "",
                                follow: "",
                                addressAboard: "",
                                hasDegree: "",
                                passNumber: "", //港澳通行证
                                identityCard: "", //中国身份证号码
                                isHandled: 0, //暂未办理
                                nows: "",
                                address: {
                                    area: [],
                                    foreign: "",
                                    details: "",
                                },
                            });
                        }
                    }
                    this.loading = false;
                }
            });
        },
        // 增加子女或兄弟姐妹
        addMember(txt) {
            if (txt === "son") {
                // 新增子女
                this.userForm.subsetList.push({
                    orderId: this.$route.params.id,
                    relation: "4",
                    subsetName: "",
                    pinyin: "",
                    usedName: "",
                    sex: "",
                    birthday: "",
                    birthPlaceAboard: 0,
                    birthPlace: {
                        area: [],
                        foreign: "",
                    },
                    address: {
                        area: [],
                        foreign: "",
                        details: "",
                    },
                    occupation: "",
                    hkIdentityCard: "",
                    wofollowrk: "",
                    addressAboard: "",
                    occupationName: "",
                    nows: "",
                    liveCountry: "",
                    liveCountryTxt: "",
                    key: Date.now(),
                    nationalityType: 3, //1-国内 2-国外
                    nationality: "" //	国外国籍信息
                });
            } else {
                // 新增兄弟姐妹
                this.userForm.subsetList.push({
                    orderId: this.$route.params.id,
                    relation: "5",
                    subsetName: "",
                    pinyin: "",
                    sex: "",
                    birthday: "",
                    birthPlaceAboard: 0,
                    birthPlace: {
                        area: [],
                        foreign: "",
                    },
                    married: "",
                    occupation: "",
                    hkIdentityCard: "",
                    addressAboard: "",
                    occupationName: "",
                    liveCountry: "",
                    liveCountryTxt: "",
                    key: Date.now(),
                    nationalityType: 3, //1-国内 2-国外
                    nationality: "" //	国外国籍信息
                });
            }
        },
        // 移除子女或兄弟姐妹
        removeMember(item) {
            this.$confirm("确定要删除该条成员信息吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let index = this.userForm.subsetList.indexOf(item);
                    if (index !== -1) {
                        this.userForm.subsetList.splice(index, 1);
                    }
                })
                .catch(() => {});
        },
        // 保存表单
        saveForm(txt) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            if (txt === "save") {
                this.isSave = true;
                this.userForm.option = "save";
                // 如果不是中国 替換参数
                this.userForm.subsetList.forEach((ele) => {
                    if (ele.liveCountry !== "中国") {
                        ele.liveCountry = ele.liveCountryTxt;
                    }
                    delete ele.liveCountryTxt;
                });
                return this.hanledSaveQuestion(2);
            } else if (txt === "last") {
                this.$router.replace({
                    name: "ltep1",
                });
            } else {
                this.userForm.option = "submit";
                this.$refs.userForm.validate((valid) => {
                    if (valid) {
                        let noPass = false; //不通過
                        for (let val of this.userForm.subsetList) {
                            // 校验出生城市国内
                            if (
                                val.birthPlaceAboard === 0 &&
                                val.birthPlace.area[0] === ""
                            ) {
                                this.$message.error("请完善出生城市");
                                noPass = true;
                                return false;
                            }
                            // 校验居住地址国内 (除父母)
                            if (val.relation === "3" || val.relation === "4") {
                                if (val.nows === 0) {
                                    if (
                                        val.addressAboard === 0 &&
                                        val.address.area[0] === ""
                                    ) {
                                        this.$message.error(
                                            "请完善目前居住地址"
                                        );
                                        noPass = true;
                                        return false;
                                    }
                                } else if (val.nows === 1) {
                                    // 判断居住地址和主申请人相同
                                    val.address = this.infomation.address;
                                }
                            }
                            // 校验身份证
                            if (val.relation === "3") {
                                if (
                                    val.identityCard !== "" &&
                                    val.identityCard !== undefined
                                ) {
                                    if (
                                        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                                            val.identityCard
                                        )
                                    ) {
                                        this.$message.error(
                                            "请输入正确的身份证号码"
                                        );
                                        return false;
                                    }
                                }
                            }
                        }
                        if (!noPass) {
                            // 如果不是中国 替換参数
                            this.userForm.subsetList.forEach((ele) => {
                                if (ele.liveCountry !== "中国") {
                                    ele.liveCountry = ele.liveCountryTxt;
                                }
                                delete ele.liveCountryTxt;
                            });
                            return this.hanledSaveQuestion(3);
                        }
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
        // 发起保存动作
        hanledSaveQuestion(parmas) {
            this.isNext = true;
            return new Promise((resolve, reject) => {
                this.userForm.subsetList.forEach((item, index) => {
                    if (item.relation == 3) {
                        item.nationalityType = 3;
                    }
                    console.log(item)
                    let family_name = ''
                    let given_name = ''
                    let i = item.pinyin.indexOf(" ")
                    if (i === -1) {
                        family_name = ''
                        given_name = item.pinyin
                    } else {
                        family_name = item.pinyin.slice(0, i)
                        given_name = item.pinyin.slice(i + 1)
                    }
                    item.subset_name_pinyin = {
                        family_name: family_name,
                        given_name: given_name
                    }
                })
                saveSubset(this.userForm).then((res) => {
                    this.isNext = false;
                    if (res.code === 200) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.saveUserData(parmas);
                            this.$message.success("提交成功！");
                            this.$router.replace({
                                name: "ltep" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                            this.isSave = false;
                        }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
        // 更改出生城市
        changeBirthPalce(e, item) {
            if (e === 0) {
                item.birthPlace.foreign = "";
            } else {
                item.birthPlace.area = [];
            }
        },
        // 更改居住国家
        changeLiveCountry(e, item) {
            if (e === "中国") {
                item.addressAboard = 0;
                item.liveCountryTxt = "";
            } else {
                item.addressAboard = 1;
            }
        },
        // 更改居住地址-是否相同
        changeNows(e, item) {
            if (e === 1) {
                item.addressAboard = "";
                item.address.foreign = "";
                item.address.details = "";
                item.address.area = [];
            }
        },
        // 更改居住地址国内国外
        changeAddress(e, item) {
            item.address.details = "";
            if (e === 0) {
                item.address.foreign = "";
            } else {
                item.address.area = [];
            }
        },
        // 已故切换
        deceasedFun(v, i) {
            if (v === 1) {
                i.married = "";
                i.occupation = "";
                i.occupationName = "";
                i.liveCountry = "";
                i.liveCountryTxt = "";
                i.hkIdentityCard = "";
            }
        },
        // 验证国籍
        validNationalityType(rule, value, callback) {
            if(!value || value === 0) {
                return callback(new Error('请选择国籍'))
            }
            return callback()
        },
        // 获取国籍
        queryCountryAsync(keyword) {
            this.opctionLoading = true;
            querySearchCountry({
                keyword,
            }).then((res) => {
                this.opctionLoading = false;
                this.countryOption = res.data;
            });
        },
        // 姓名失去焦点获取拼音
        usernameBlur(item) {
            console.log(item)
            if(!/^[\u4E00-\u9FFF\u3400-\u4DBF]+$/.test(item.subsetName)) {
                item.pinyin = ''
                return
            }
            getPingYin({
                chinese: item.subsetName,
            }).then((res) => {
                let given_name = res.data.given_name ? ' ' + res.data.given_name : ''
                item.pinyin = res.data.family_name + given_name
            });
        },
        // 姓名拼音只能输入 字母和空格
        pinyinInput(item) {
            item.pinyin = item.pinyin.replace(/[^a-zA-Z ]/g, '').toLocaleUpperCase();
        }
    },
};
</script>

<style lang="less" scoped>
.one-input {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.sigh-img {
    position: absolute;
}

.input-width {
    width: 338px;
}

.name-width {
    width: 282px;
}

.full-width {
    width: 345px;
}

.check-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-checkbox {
    top: -10px;
    left: 10px;
}

.see-right {
    display: block;
}

.el-radio {
    margin-right: 21px;
}

.sis-box {
    border-bottom: 1px solid #dcdfe6;
}

.city-input-width {
    width: 200px;
}

.in-city {
    display: block;
}

.parent-line {
    &::before {
        content: "";
        width: 928px;
        height: 1px;
        background: #dcdfe6;
        position: absolute;
        bottom: 0;
        left: 16px;
    }
}

.inline-item {
    display: inline-block;
}

.flex-center {
    display: flex;
    align-items: flex-start;
}

.city-inline {
    display: inline-block;
}

.el-form-item--small.el-form-item {
    margin-bottom: 24px;
}

.sister-box {
    border-bottom: 1px solid #dcdfe6;

    .sister-heard {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 16px;
        color: #1890ff;
        height: 36px;
    }
}

.add-people {
    width: 928px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    cursor: pointer;
}

.block-heard {
    border: none;
}

.del-cur {
    cursor: pointer;
}

.detailed-address {
    width: 338px;
}

/deep/.el-scrollbar .el-select-dropdown__wrap {
    margin-bottom: -16px !important;
}

/deep/.el-select-dropdown__wrap {
    border: none !important;
}

/deep/.el-scrollbar .el-cascader-menu__wrap {
    margin-bottom: -16px !important;
}

/deep/.el-scrollbar .el-cascader-menu__wrap {
    margin-bottom: -16px !important;
}
</style>
